import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import map from 'lodash/map'
import slice from 'lodash/slice'
import size from 'lodash/size'

import { DesktopSlider, MerchantGridCard } from 'Components/Blocks'

import { Box } from 'Components/UI'

import { useBaseTheme } from 'Hooks'
import {
  DesktopView,
  FeaturedMerchantsInnerContainer,
  FeaturedMerchantsMobileContainer,
  FeaturedMerchantsMobileSlider,
} from './styles'

function FeaturedMerchantsSliders({ sliderSettings, featuredBusinesses }) {
  const { isPortableDevice } = useBaseTheme()

  return (
    <>
      {isPortableDevice && (
        <FeaturedMerchantsMobileContainer>
          <LazyLoad height="400px" once>
            <FeaturedMerchantsMobileSlider {...sliderSettings}>
              {map(slice(featuredBusinesses, 0, 8), merchant => (
                <Box key={merchant.token} px="5px" py="8px">
                  <MerchantGridCard merchant={merchant} />
                </Box>
              ))}
            </FeaturedMerchantsMobileSlider>
          </LazyLoad>
        </FeaturedMerchantsMobileContainer>
      )}

      {!isPortableDevice && (
        <DesktopView>
          {size(featuredBusinesses) === 4 || size(featuredBusinesses) === 8 ? (
            <FeaturedMerchantsInnerContainer>
              {map(slice(featuredBusinesses, 0, 8), merchant => (
                <LazyLoad height="420px" key={merchant.token} once>
                  <MerchantGridCard merchant={merchant} />
                </LazyLoad>
              ))}
            </FeaturedMerchantsInnerContainer>
          ) : (
            <DesktopSlider>
              {map(featuredBusinesses, merchant => (
                <LazyLoad height="420px" key={merchant.token} once>
                  <Box px="5px" py="8px">
                    <MerchantGridCard merchant={merchant} />
                  </Box>
                </LazyLoad>
              ))}
            </DesktopSlider>
          )}
        </DesktopView>
      )}
    </>
  )
}

FeaturedMerchantsSliders.propTypes = {
  featuredBusinesses: PropTypes.array.isRequired,
  sliderSettings: PropTypes.object.isRequired,
  topOffers: PropTypes.array.isRequired,
}

export default FeaturedMerchantsSliders
