import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setCategoryValue, loadAllCategories } from 'Store/Actions/marketplace'

import {
  getFeaturedCategories,
  getFeaturedCategoriesLoading,
  getFeaturedMerchants,
  getFeaturedMerchantsLoading,
  getAllCategories,
  getFeaturedLocations,
  getFeaturedLocationsLoading,
} from 'Store/Selectors/marketplace'

import Home from './Home'

const selector = createStructuredSelector({
  featuredCategories: getFeaturedCategories,
  featuredLocations: getFeaturedLocations,
  featuredMerchants: getFeaturedMerchants,
  isFeaturedCategoriesLoading: getFeaturedCategoriesLoading,
  isFeaturedLocationsLoading: getFeaturedLocationsLoading,
  isFeaturedMerchantsLoading: getFeaturedMerchantsLoading,
  allCategories: getAllCategories,
})

const actions = {
  onSetCategoryValue: setCategoryValue,
  onLoadAllCategories: loadAllCategories,
}

export default connect(selector, actions)(Home)
